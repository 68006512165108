import { cultures } from "@bambora/cultures";
import * as Wallet from "@bambora/wallet";
import { IAuthorizeResult } from "@bambora/wallet";
import { IGooglePaySessionData, IGooglePayCallbackResponse } from "@bambora/wallet/dist/types/clients/googlepay";
import { IApplePaySessionData } from "@bambora/wallet/dist/types/clients/applepay";
import { States } from "../../app.states";
import {
    AuthorizeService,
    ErrorService,
    SessionService,
    AppStateService,
    UrlService,
    ExpressCheckoutService,
    ConfigReaderService
} from "../../core/index";

import * as WalletConstants from "./wallet.constants";

export class WalletService {

    private googlePay?: Wallet.GooglePay;
    private _isGooglePayAvailable: boolean = false;
    private applePay?: Wallet.ApplePay;
    private _isApplePayAvailable: boolean = false;

    static $inject: string[] = [
        "AuthorizeService",
        "ExpressCheckoutService",
        "AppStateService",
        "ErrorService",
        "SessionService",
        "UrlService",
        "ConfigReaderService",
        "gettextCatalog",
        "$http",
        "$q",
        "$state"
    ];

    public constructor(
        private authorizeService: AuthorizeService,
        private expressCheckoutService: ExpressCheckoutService,
        private appStateService: AppStateService,
        private errorService: ErrorService,
        private sessionService: SessionService,
        private urlService: UrlService,
        private configReaderService: ConfigReaderService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private $http: ng.IHttpService,
        private $q: ng.IQService,
        private $state
    ) { }

    get isApplePayAvailable() {
        return this.applePay && this._isApplePayAvailable;
    }

    get applePayWallet() {
        return this.applePay;
    }

    get isGooglePayAvailable() {
        return this.googlePay && this._isGooglePayAvailable;
    }

    get googlePayWallet() {
        return this.googlePay;
    }

    initializeWallets(): ng.IPromise<void[]> {
        return this.$q.all([this.initializeGooglePay(), this.initializeApplePay()]);
    }

    showErrorMessage(context: string): boolean {
        return this.errorService.errorContext === context;
    }

    sessionProvider<T>(
        context: string,
        walletName: WalletConstants.IWalletNameConstants,
        acceptUrl: string,
        declineUrl: string,
        skipLoading: boolean,
        appReturnUrl?: string,
        additionalData?: Server.data[]
    ): ng.IPromise<Wallet.Session<T>> {
        if (!skipLoading) {
            this.appStateService.startFullScreenLoadingWithMessage(this.gettextCatalog.getString("Connecting to") + " " + walletName.walletDisplayName);
        }

        return this.createWalletSession(context, walletName.walletName, acceptUrl, declineUrl, appReturnUrl, additionalData)
            .then((session) => {
                const sessionData = session.data.reduce((previous, current) => {
                    previous[current.key] = current.value
                    return previous
                }, {} as T);

                return { data: sessionData };
            });
    }

    openWallet(context: string, start: () => ng.IPromise<IAuthorizeResult | void>, acceptState: string) {
        start()
            .then((authorizeResult) => {
                if (!authorizeResult) return;
                this.handleAuthorizeResult(authorizeResult, context, acceptState);
            })
            .catch((error) => {
                if (error && error.Code && 
                    (error.Code == Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized ||
                    error.Code == Server.ActionCodeTypeEnum.SessionExpired)) {
                    return;
                }
                this.handleError(context)
            })
    }

    private createWalletSession(
        context: string,
        walletName: string,
        acceptUrl: string,
        declineUrl: string,
        appReturnUrl: string,
        additionalData?: Server.data[],
    ): ng.IPromise<Server.session> {
        const request: Server.createwalletsessionrequest = {
            accepturl: acceptUrl,
            declineurl: declineUrl,
            appreturnurl: appReturnUrl,
            data: additionalData
        };

        return this.expressCheckoutService.createWalletSession(this.sessionService.sessionToken, walletName, request)
            .then((response: Server.createwalletsessionresponse) => {
                if (!response.meta.result) {

                    if (response.meta.action.code === Server.ActionCodeTypeEnum.SessionExpired.toString()) {
                        this.sessionService.status = "expired";
                        this.appStateService.switchState(States.Session.Expired.name);
                        this.appStateService.cancelLoading();
                        throw new CreateWalletSessionError(Server.ActionCodeTypeEnum.SessionExpired);
                    }
                    throw new CreateWalletSessionError();
                }

                if (response.meta.action.code === Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized.toString()) {
                    this.handleAuthorizeResult({ authorizeResult: true, redirectUrl: response.createwalletsessionresponseurl, wait: false, stepUp: false }, context);
                    throw new CreateWalletSessionError(Server.ActionCodeTypeEnum.SuccessAlreadyAuthorized);
                }

                return response.session;
            });
    }

    private handleAuthorizeResult(authorizeResult: Wallet.IAuthorizeResult, context: string, state?: string) {
        if (authorizeResult.authorizeResult) {
            this.sessionService.status = "authorized";
            this.urlService.setUrlFromWalletResponse(authorizeResult.redirectUrl, this.sessionService.sessionToken);
            this.urlService.setAuthorizeParams(authorizeResult.redirectUrl, this.sessionService.sessionToken);
            this.appStateService.windowState.dispatcher.updateOptions({ acceptUrl: this.urlService.acceptUrl });
            this.appStateService.switchState(state || States.Session.Accept.name);
            this.appStateService.cancelLoading();
            return;
        }

        if (authorizeResult.wait && !authorizeResult.authorizeResult) {
            window.location.href = authorizeResult.redirectUrl;
            return;
        }

        this.handleError(context);
    }

    private handleError(context: string, message?: string) {
        var errorMessage = message || this.gettextCatalog.getString("The payment could not be processed, please try again or choose another payment method.");

        this.errorService.displayErrorMessage(errorMessage, context);
        this.appStateService.cancelLoading();
    }

    private formatAmount(amount: number, currencyCode: string) {
        let decimals = 2;
        const culture = cultures.find((c) => c.currency.isoSymbol === currencyCode);

        if (culture) {
            decimals = culture.currency.format.decimalDigits
        }

        return (amount / Math.pow(10, decimals)).toFixed(decimals)
    }

    private walletThreeDInitiate(initiateUrl: string, browserInformation: Server.browserinformation): ng.IPromise<Server.walletthreedinitiateresponse> {
        return this.$http.post(initiateUrl, browserInformation)
            .then((response: ng.IHttpResponse<Server.walletthreedinitiateresponse>) => {
                return response.data;
            });
    }

    private initializeGooglePay(): ng.IPromise<void> {
        if (!this.sessionService.isGooglePayAvailable) return;

        const googlePaymentType = this.sessionService.googlePayPaymentType;
        if (!googlePaymentType) return;

        const googlePayMerchantOrigin = googlePaymentType.agreement.agreementdata.find((ad) => ad.key == "MerchantOrigin");
        if (!googlePayMerchantOrigin || !googlePayMerchantOrigin.value) return;

        const googlePayGatewayMerchantId = googlePaymentType.agreement.agreementdata.find((ad) => ad.key == "GatewayMerchantId");
        if (!googlePayGatewayMerchantId || !googlePayGatewayMerchantId.value) return;

        const acceptUrl = this.$state.href(States.Session.PaymentCard.ReturnFrom3DAccept.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });
        const declineUrl = this.$state.href(States.Session.PaymentCard.ReturnFrom3DDecline.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });

        // const googlePaymentTypes = this.sessionService.paymentCardTypes
        //     .filter((pg) => pg.name.toLowerCase() == "mastercard" || pg.name.toLowerCase() == "visa")
        //     .map((pt) => pt.name.toUpperCase() as google.payments.api.CardNetwork);

        return Wallet.GooglePay.create({
            clientConfiguration: {
                environment: this.sessionService.merchant.id.substring(0, 1) === "T" ? "TEST" : "PRODUCTION"
            },
            sessionProvider: {
                fetchSession: async () => {
                    return this.sessionProvider<IGooglePaySessionData>(
                        "expressoption",
                        WalletConstants.GOOGLEPAY,
                        acceptUrl,
                        declineUrl,
                        false,
                        this.appStateService.options.appReturnUrl,);
                }
            },
            authorizeProvider: {
                authorizePayment: Wallet.DefaultGooglePayAuthorizeProvider.prototype.authorizePayment,
                stepUp: async (authorizeResult: IGooglePayCallbackResponse) => {
                    this.walletThreeDInitiate(authorizeResult.redirectUrl, this.authorizeService.getBrowserInformation())
                        .then((initiateResponse) => {
                            if (!initiateResponse.meta.result) {
                                this.handleError("expressoption", initiateResponse.meta.message.enduser);
                                return;
                            }
                            this.authorizeService.authorizeWalletStepUp(initiateResponse)
                                .then((response) => {
                                    if (!response.meta.result) {
                                        this.handleError("expressoption", response.meta.message.enduser);
                                    } else {
                                        this.handleAuthorizeResult({ authorizeResult: true, redirectUrl: response.authorizeresponseurl, wait: false, stepUp: false }, "expressoption", States.Session.GooglePay.Accept.name);
                                    }
                                });
                        })
                        .catch(() => {
                            this.handleError("expressoption");
                        });
                    return;
                },
            }
        },{
            merchantInfo: {
                merchantId: "BCR2DN4TYHYLBMZZ",
                merchantName: this.sessionService.merchant.name,
                merchantOrigin: googlePayMerchantOrigin.value
            } as any,
            allowedPaymentMethods: [{
                parameters: {
                    allowedCardNetworks: ["MASTERCARD", "VISA"]
                },
                tokenizationSpecification: {
                    parameters: {
                        gatewayMerchantId: googlePayGatewayMerchantId.value,
                    }
                }
            }],
            transactionInfo: {
                currencyCode: this.sessionService.order.currency,
                countryCode: this.sessionService.merchant.countrycode,
                totalPriceStatus: "FINAL",
                totalPrice: this.formatAmount(this.sessionService.order.amount, this.sessionService.order.currency),
            }
        }).then((googlePay) => {
            this.googlePay = googlePay;
            return googlePay.isReady();
        }).then((result) => {
            this._isGooglePayAvailable = result;
        }).catch(() => {
            this.googlePay = null;
            this._isGooglePayAvailable = false;
        });
    }

    private initializeApplePay(): ng.IPromise<void> {
        if (!this.sessionService.isApplePayAvailable) return;

        const acceptUrl = this.$state.href(States.Session.ApplePay.Accept.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });
        const declineUrl = this.$state.href(States.Session.ApplePay.Decline.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });

        return Wallet.ApplePay.create({
            sessionProvider: {
                fetchSession: async () => {
                    return this.sessionProvider<IApplePaySessionData>(
                        States.Session.ApplePay.name,
                        WalletConstants.APPLEPAY,
                        acceptUrl,
                        declineUrl,
                        true,
                        this.appStateService.options.appReturnUrl,
                        [{
                            key: "Domain",
                            value: this.configReaderService.applePayDomain
                        },{
                            key: "DisplayName",
                            value: this.sessionService.merchant.name
                        }]);
                }
            }
        },{
            countryCode: this.sessionService.merchant.countrycode,
            currencyCode: this.sessionService.order.currency,
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: ["masterCard", "visa"],
            total: {
                label: this.sessionService.merchant.name,
                amount: this.formatAmount(this.sessionService.order.amount, this.sessionService.order.currency)
            }
        }).then((applePay) => {
            this.applePay = applePay;
            return applePay.isAvailable(this.configReaderService.applePayMerchantId);
        }).then((result) => {
            this._isApplePayAvailable = result;
        }).catch(() => {
            this.applePay = null;
            this._isApplePayAvailable = false;
        });
    }
}

class CreateWalletSessionError extends Error {
    public Code?: Server.ActionCodeTypeEnum

    constructor(code?: Server.ActionCodeTypeEnum) {
        super();
        this.Code = code;
    }
}
