import { AppStateService, Component, MerchantService, SessionService } from "../../../core/index";
import { ApplePayButtonLocale, IApplePaySessionData } from "@bambora/wallet/dist/types/clients/applepay";

import { ApplePay } from "@bambora/wallet";
import { States } from "../../../app.states";
import { WalletConstants } from "../../";
import { WalletService } from "../../index";

const styles = {
    base: require("../../../styles/base.scss"),
    buttons: require("../../../styles/buttons.scss"),
    applepay: require("./applepay-start.scss")
};


@Component({
    selector: "becApplePayStart",
    template: require("./applepay-start.html")
})
export class ApplePayStart {
    styles = styles;
    paymentTypeName = "ApplePay";

    static $inject = [
        "WalletService", "gettextCatalog", "AppStateService"
    ];

    constructor(
        private walletService: WalletService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        public appState: AppStateService
    ) { }

    $onInit() {
        if (this.walletService.isApplePayAvailable) {
            const container = document.getElementById("applePayButtonContainer");

            this.walletService.applePayWallet.createButton(container, {
                buttonLocale: this.gettextCatalog.getCurrentLanguage() as ApplePayButtonLocale,
                buttonStyle: "black",
                buttonType: "plain",
                onClick: () => this.walletService.openWallet("expressoption", this.walletService.applePayWallet.start, States.Session.ApplePay.Accept.name)
            });
        }
    }
}
