import { ICustomerDetails } from "../../../core";
import { 
    Component, 
    SessionService, 
    CustomerService, 
    AppStateService, 
    MerchantService, 
    Country 
} from "../../../core/index";
import { PhoneNumberService } from "../../../payment-card/shared/index"
import { WalletService } from "../../index";
import { WalletConstants } from "../../";
import { States } from "../../../app.states";
import { MobilePay } from "@bambora/wallet";
import { IMobilePaySessionData } from "@bambora/wallet/dist/types/clients/mobilepay";

import base from "../../../styles/base.scss";
import buttons from "../../../styles/buttons.scss";
import input from "../../../shared/components/input/input.scss";
import mobilepay from "./mobilepay-start.scss";

const styles = {
    base: base,
    buttons: buttons,
    input: input,
    mobilepay: mobilepay
};

@Component({
    selector: "becMobilePayStart",
    template: require("./mobilepay-start.html")
})
export class MobilePayStart {
    styles = styles;
    customer: ICustomerDetails;
    phonePlaceholder: string;
    paymentTypeName = "MobilePay";

    static $inject = [
        "WalletService", "SessionService", "CustomerService", "gettextCatalog", "$state", "AppStateService", "MerchantService", "PhoneNumberService"
    ];

    constructor(
        private walletService: WalletService,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private $state,
        public appState: AppStateService,
        private merchantService: MerchantService,
        private phoneNumberService: PhoneNumberService
    ) {
        this.customer = customerService.details;
        this.phonePlaceholder = this.gettextCatalog.getString("Phone number");
    }

    $onInit() {
        if (!this.customer.phonenumbercountrycode) {
            this.setDefaultCountryCode();
        }
    }

    setDefaultCountryCode() {
        switch (this.merchantService.Country) {
            case Country.Denmark:
                this.customer.phonenumbercountrycode = "dk";
                break;
            case Country.Finland:
                this.customer.phonenumbercountrycode = "fi";
                break;

            default:
                this.customer.phonenumbercountrycode = "";
                break;
        }
    }

    isDemo() {
        return this.sessionService.isDemo();
    }

    hasError() {
        return this.walletService.showErrorMessage(States.Session.MobilePay.name);
    }

    continue() {
        this.openWallet();
    }

    private openWallet() {
        const acceptUrl: string = this.$state.href(States.Session.MobilePay.Accept.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });
        const declineUrl: string = this.$state.href(States.Session.MobilePay.Decline.name,
            { token: this.sessionService.sessionToken },
            { absolute: true });

        const additionalData: Server.data[] = [
            {
                key: "PhoneNumberCountryCode", value: this.phoneNumberService.getCallingCodeFromCountryCode(this.customer.phonenumbercountrycode.toUpperCase())
            },
            {
                key: "PhoneNumber", value: this.customer.phonenumber
            },
            {
                key: "ShopLogoUrl", value: this.sessionService.merchant.logourl
            },
            {
                key: "ShopName", value: this.sessionService.merchant.name
            }
        ];

        if (this.sessionService.merchant.logourl) {
            additionalData.push({ key: "MerchantLogoUrl", value: this.sessionService.merchant.logourl });
        }

        if (this.appState.isAppSwitchSupported("mobilepay")) {
            additionalData.push({ key: "IsApp", value: "true" })
        }

        const mobilePayWallet = MobilePay.create({
            sessionProvider: {
                fetchSession: async () => {
                    return this.walletService.sessionProvider<IMobilePaySessionData>(
                        States.Session.MobilePay.name,
                        WalletConstants.MOBILEPAY,
                        acceptUrl,
                        declineUrl,
                        false,
                        this.appState.options.appReturnUrl,
                        additionalData);
                }
            }
        }, { target: "_top" });

        this.walletService.openWallet(States.Session.MobilePay.name, mobilePayWallet.start, States.Session.MobilePay.Accept.name);
    }

    getPhoneNumberInputContainerClasses(phoneNumberInvalid, focusPhone) {
        return `${styles.mobilepay.countryCodeContainer} ${phoneNumberInvalid ? styles.input.invalid : ""
            } ${focusPhone ? styles.input.inputField : ""
            }`;
    }
}
