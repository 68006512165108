import * as _ from "underscore";

import {
    AppStateService,
    Component,
    CustomerService,
    FeeService,
    PaymentCardService,
    SessionService
} from "../../../core/index";
import { IStateWithParams, WindowStateService } from '../../../core';

import { CheckoutEvent } from '@bambora/checkout-core-events';
import { States } from "../../../app.states";
import { WalletService } from '../../../wallets/shared/wallet.service';

import paymentMethods from "./payment-methods.scss";
import buttons from "../../../styles/buttons.scss";
import radioButton from "../../../shared/components/radio-button/radio-button.scss";

const styles = {
    paymentMethods: paymentMethods,
    buttons: buttons,
    radioButton: radioButton
};

@Component({
    selector: "becPaymentMethods",
    template: require("./payment-methods.html"),
    bindings: {
        selected: "<selectedMethod"
    }
})
export class PaymentMethodsComponent {
    static $inject = [
        "AppStateService",
        "SessionService",
        "gettextCatalog",
        "PaymentCardService",
        "CustomerService",
        "FeeService",
        "WalletService",
        "$state",
        "$rootScope",
        "WindowStateService",
        "$window"
    ];

    styles = styles;

    constructor(
        private appState: AppStateService,
        private sessionService: SessionService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private paymentCardService: PaymentCardService,
        private customerService: CustomerService,
        private feeService: FeeService,
        private walletService: WalletService,
        private $state: ng.ui.IStateService,
        private $rootScope: ng.IRootScopeService,
        private windowService: WindowStateService,
        private $window: ng.IWindowService
    ) {
        this.paymentMethods = [];
        this.expressOptions = [];

        if (this.sessionService.isPaymentCardAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Payment card"),
                state: States.Session.PaymentCard.Start.name,
                methodName: "paymentcard"
            });

        if (sessionService.isMobilePayAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("MobilePay"),
                state: States.Session.MobilePay.Start.name,
                methodName: "mobilepay"
            });

        if (this.sessionService.isVippsAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Vipps"),
                state: States.Session.Vipps.Start.name,
                methodName: "vipps"
            });

        if (this.sessionService.isInvoiceAvailable) {
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Invoice"),
                state: States.Session.Invoice.Start.name,
                methodName: "invoice"
            });
        }
        if (this.sessionService.isPartPaymentAvailable) {
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Part payment"),
                state: States.Session.Invoice.Start.name,
                methodName: "partpayment"
            });
        }

        if (this.sessionService.isEkspresBankAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Ekspres Bank"),
                state: States.Session.EkspresBank.Start.name,
                methodName: "ekspresbank"
            });

        if (this.sessionService.isSwishAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Swish"),
                state: States.Session.Swish.Start.name,
                methodName: "swish"
            });

        if (this.sessionService.isViaBillAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("ViaBill"),
                state: States.Session.ViaBill.Start.name,
                methodName: "viabill"
            });

        if (this.sessionService.isNordeaEPaymentFIAvailable)
            this.paymentMethods.push({
                name: this.gettextCatalog.getString("Direct Banking"),
                state: States.Session.NordeaEPaymentFI.Start.name,
                methodName: "nordeaepaymentfi"
            });

        if (this.sessionService.isApplePayAvailable &&
            this.walletService.isApplePayAvailable) {
            this.expressOptions.push({
                methodName: "applepay"
            });
        }

        if (this.sessionService.isGooglePayAvailable &&
            this.walletService.isGooglePayAvailable) {
            this.expressOptions.push({
                methodName: "googlepay"
            });
        }

        if (this.paymentMethods.length === 1) {
            this.setSelectedPaymentOption(null, this.paymentMethods[0].methodName);
        } else if (this.selectedMethod) {
            this.setSelectedPaymentOption(null, this.selectedMethod.toLowerCase());
        } else if (this.customerService.hasBeenRememberedForPaymentCard) {
            this.setSelectedPaymentOption(null, "paymentcard");
        } else if (!PaymentMethodsComponent.isMobileDevice() && this.paymentMethods.length > 0) {
            this.setSelectedPaymentOption(null, this.paymentMethods[0].methodName);
        } else if (this.paymentMethods.length === 0 && this.expressOptions.length > 0) {
            document.body.classList.add("onlyExpressOptions");
        }
    }

    paymentMethods: Array<{ name: string; state: string; methodName: string }> = [];
    expressOptions: Array<{ methodName: string }> = [];
    visible = false;

    get selectedMethod() {
        return this.appState.currentPaymentMethod();
    }

    set selected(value) { }

    get paymentMethodTabIds(): string {
        return this.paymentMethods.reduce(
            (acc, val) => `${acc} tab_${val.methodName}`,
            ""
        ).trim();
    }

    toggle = () => {
        this.visible = !this.visible;
    };

    hide = () => {
        this.visible = false;
    };

    cancel() {
        this.sessionService.cancel(null);
    }

    hasExpressOptionError() {
        return this.walletService.showErrorMessage("expressoption");
    }

    onPaymentMethodContainerMouseDown(event: FocusEvent) {
        const element = $(event.target);

        if (element.hasClass(styles.paymentMethods.paymentMethodContainer)) {
            $(event.target).addClass("focus-by-mouse");
            return;
        }

        element.parents(`.${styles.paymentMethods.paymentMethodContainer}`).addClass("focus-by-mouse");
    }

    onPaymentMethodContainerBlur(event: FocusEvent) {
        $(event.target).removeClass("focus-by-mouse");
    }

    get selectedPaymentOption() {
        return this._selectedPaymentOption;
    }

    set selectedPaymentOption(value) { }

    isSelected(cardType: string) {
        if (!this.selectedPaymentCardTypes) return false;
        if (this.selectedPaymentCardTypes.map(paymentType => paymentType.name).indexOf(cardType) > -1) return true;
        return false;
    }

    isDeselected(cardType: string) {
        if (!this.selectedPaymentCardTypes) return false;
        if (this.selectedPaymentCardTypes.map(paymentType => paymentType.name).indexOf(cardType) > -1) return false;
        return true;
    }

    setSelectedPaymentOption($event: Event, value: string) {
        if (this.selectedPaymentOption === value) return;

        let state = null;

        switch (value.toLowerCase()) {
            case "paymentcard": state = States.Session.PaymentCard.Start.name; break;
            case "invoice": state = States.Session.Invoice.Start.name; break;
            case "partpayment": state = States.Session.PartPayment.Start.name; break;
            case "ekspresbank": state = States.Session.EkspresBank.Start.name; break;
            case "mobilepay": state = States.Session.MobilePay.Start.name; break;
            case "vipps": state = States.Session.Vipps.Start.name; break;
            case "applepay": state = States.Session.ApplePay.Start.name; break;
            case "googlepay": state = States.Session.GooglePay.Start.name; break;
            case "swish": state = States.Session.Swish.Start.name; break;
            case "viabill": state = States.Session.ViaBill.Start.name; break;
            case "nordeaepaymentfi": state = States.Session.NordeaEPaymentFI.Start.name; break;
        }

        if (state) {
            this.$state.go(state, null, {
                location: "replace",
                notify: false,
                reload: false
            });
        }

        this.appState.lastAccordionExpandType = $event && $event.type === "click" && !PaymentMethodsComponent.isMobileDevice() ? "mouse" : "keyboard";

        this.$rootScope.$emit("paymentMethodSelected", value.toLowerCase());

        this._selectedPaymentOption = value;

        this.appState.windowState.dispatcher.dispatch(CheckoutEvent.PaymentTypeSelection, value.toLowerCase());
    }

    private _selectedPaymentOption = null;

    get selectedPaymentCardTypes() {
        if (!this.paymentCardService.paymentcard) return null;

        const ccnum = this.paymentCardService.paymentcard.cardnumber;

        if (!ccnum) return null;

        if (this.feeService.paymentTypes) return this.feeService.paymentTypes;

        return null;
    }

    get hideOnMobile() {
        return this.availableCardTypes.length > 4;
    }

    get availableCardTypes() {
        try {
            return this.sessionService.paymentCardTypes.map(paymentGroup => paymentGroup.name);
        } catch (e) {
            return [];
        }
    }

    get cheapestPartPaymentOption(): Server.paymentoption {
        if (!this.sessionService.invoiceOptions) return null;

        let partPaymentOptions = _.select<any>(this.sessionService.invoiceOptions.paymentoptions, item => item.type === "AnnuityInvoice" || item.type === "InterestFreeInvoice");

        return _.min<Server.paymentoption>(
            partPaymentOptions,
            paymentOption => paymentOption.paymentdetails.monthlyamount
        );
    }

    get cheapestPartPaymentOptionMonthlyAmount(): number {
        let cheapestOption = this.cheapestPartPaymentOption;
        if (!cheapestOption) return null;

        return cheapestOption.paymentdetails.monthlyamount
    }


    static isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf("IEMobile") !== -1);
    }
}
